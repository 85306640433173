<template>
  <div class="usermod_box">
    <div class="usermod_tabs">
      <process ref="next_li" :name_list="name_list"></process>
    </div>
    <div class="binding_ii" v-if="plans === 1">
      <div class="set_binding">
        <div class="input">
          <span class="title">管理员注册手机号：</span>

          <div class="clr">
            <div class="fl tell">
              <el-input v-model="user.mobile" disabled>
                <template slot="prepend">+86</template>
              </el-input>
            </div>
            <div class="fr">
              <el-button type="text" @click="set_tell(0)">修改手机号</el-button>
            </div>
          </div>
        </div>
        <div class="wx_code">
          <span class="title">管理员已绑定的个人微信:</span>
          <div class="clr">
            <div class="fl tell">
              <div class="code">
                <div class="img">
                  <img :src="user.headimgurl" alt />
                </div>
                <div class="name">{{ user.nickname }}</div>
              </div>
            </div>
            <div class="fr">
              <el-button type="text" @click="set_wx(0)">修改微信号</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="binding_ii" v-else-if="plans === 2">
      <div class="tell_list">
        <el-tag
          type="primary"
          @click="
            plans = 1;
            $refs.next_li.nexts(1);
          "
        >
          <i class="el-icon-arrow-left"></i> 返回 暂不修改
        </el-tag>
        <div class="title">验证原绑定手机</div>
        <div class="tell">
          <el-input
            placeholder="请输入您的手机号"
            v-model="user.mobile"
            disabled
            clearable
          >
            <template slot="prepend">+86</template>
          </el-input>
        </div>
        <div class="code_img">
          <el-input placeholder="请输入手机验证码" v-model="out_code" clearable>
            <el-button slot="append" @click="get_code(1)" v-if="!time.count"
              >获取验证码</el-button
            >
            <el-button slot="append" v-else
              >{{ time.count }}获取验证码</el-button
            >
          </el-input>
        </div>
        <div class="set_btn">
          <el-button type="primary" @click="set_tell(1)"
            >验证原手机号</el-button
          >
        </div>
      </div>
    </div>
    <div class="binding_ii" v-else-if="plans === 3">
      <div class="tell_list">
        <div class="title">绑定新手机号</div>
        <div class="tell">
          <el-input
            placeholder="请输入您的新手机号"
            v-model="new_tell.tell"
            clearable
          >
            <template slot="prepend">+86</template>
          </el-input>
        </div>
        <div class="code_img">
          <el-input
            placeholder="请输入新手机验证码"
            v-model="new_tell.code"
            clearable
          >
            <el-button slot="append" @click="get_code(2)" v-if="!time.count"
              >获取验证码</el-button
            >
            <el-button slot="append" v-else
              >{{ time.count }}获取验证码</el-button
            >
          </el-input>
        </div>
        <div class="set_btn">
          <el-button type="primary" @click="set_tell(2)">绑定新手机</el-button>
        </div>
      </div>
    </div>
    <div class="binding_ii" v-else-if="plans === 5">
      <div class="tell_list">
        <el-tag
          type="primary"
          @click="
            plans = 1;
            $refs.next_li.nexts(1);
          "
        >
          <i class="el-icon-arrow-left"></i> 返回 暂不修改
        </el-tag>
        <div class="title">验证原绑定微信</div>
        <div class="out_code">
          <div class="code">
            <code-list :status="1" @code_off="set_wx" />
          </div>
        </div>
        <div class="out_hint">
          请用原管理员微信扫描二维码
          <br />完成新微信号的绑定
        </div>
      </div>
    </div>
    <div class="binding_ii" v-else-if="plans === 6">
      <div class="tell_list">
        <div class="title">扫码绑定新微信</div>
        <div class="out_code">
          <div class="code">
            <code-list :status="2" @code_off="set_wx" />
          </div>
        </div>
        <div class="out_hint">
          请用新管理员微信扫描二维码
          <br />完成新微信号的绑定
        </div>
      </div>
    </div>
    <div class="binding_ii" v-else-if="plans === 7">
      <div class="tell_list">
        <div class="iconfont tac iconchenggong"></div>
        <div class="title tac">恭喜您 ，修改成功 ！</div>
        <div class="tac">
          <el-button
            type="primary"
            class="tac"
            size="medium"
            @click="
              plans = 1;
              $refs.next_li.nexts(1);
            "
            >查看最新绑定状态</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CodeList from "@/components/code_list";
import { isvalidatemobile } from "@/assets/js/validate";
import { Process } from "@/components/verify_firm";
import { mapGetters } from "vuex";
export default {
  components: {
    Process,
    CodeList,
  },
  data() {
    return {
      plans: 1,
      name_list: {
        one: "选择更改的绑定",
        two: "验证原有绑定",
        three: "绑定新账号",
        four: "修改完成",
      },
      out_code: "",
      time: {
        count: "",
        show: false,
        timer: "",
      },
      new_tell: {
        tell: "",
        code: "",
      },
    };
  },
  mounted() {},
  methods: {
    set_tell(e) {
      let that = this;
      let info = "";
      switch (e) {
        case 0:
          that.$refs.next_li.nexts(2);
          that.plans = 2;
          break;
        case 1:
          if (!that.out_code) {
            that.$message.error("验证码不能为空！");
            return false;
          }
          info = {
            mobile: that.user.mobile,
            mobileCode: that.out_code,
          };
          that.$api.oldmobile_put(info).then((res) => {
            if (res.errcode === 0) {
              that.$refs.next_li.nexts(3);
              that.plans = 3;
              clearInterval(that.time.timer);
              that.time.timer = "";
              that.time.count = "";
            }
          });
          break;
        case 2:
          let status = isvalidatemobile(that.new_tell.tell);
          if (status[0]) {
            that.$message.error(status[1]);
            return false;
          }
          info = {
            mobile: that.new_tell.tell,
            mobileCode: that.new_tell.code,
          };
          that.$api.newmobile_put(info).then((res) => {
            if (res.errcode === 0) {
              clearInterval(that.time.timer);
              that.time.timer = "";
              that.time.count = "";
              that.$refs.next_li.nexts(4);
              that.plans = 7;
            }
          });
          break;
        case 3:
          that.$refs.next_li.nexts(5);
          that.plans = 7;
          api.users().then((res) => {
            that.$store.commit("set_user", res.data);
          });
          break;
      }
    },
    set_wx(e) {
      let that = this;
      switch (e) {
        case 0:
          // 原二维码
          that.$refs.next_li.nexts(2);
          that.plans = 5;
          break;
        case -1:
          // 新二维码
          that.$refs.next_li.nexts(3);
          that.plans = 6;
          break;
        case -2:
          that.$refs.next_li.nexts(4);
          that.plans = 7;
          that.$api.users().then((res) => {
            that.$store.commit("set_user", res.data);
          });
          break;
      }
    },
    get_code(i) {
      let that = this;
      if (i === 1) {
        // 发验证码给旧手机
        let info = {
          mobile: this.user.mobile,
        };
        that.$api.oldmobile_post(info).then((res) => {
          if (res.errcode === 0) {
            this.$message.success("验证码发送成功，请用原手机号查收验证码");
            that.time.count = 60;
            that.time.show = false;
            that.time.timer = setInterval(() => {
              if (that.time.count > 0) {
                that.time.count--;
              } else {
                that.time.show = true;
                clearInterval(that.time.timer);
                that.time.timer = null;
                that.time.count = "";
              }
            }, 1000);
          }
        });
      } else {
        if (!that.new_tell.tell) {
          this.$message.error("请您填写新手机号再发送！");
          return false;
        }
        // 发验证码给新手机
        let info = {
          mobile: that.new_tell.tell,
        };
        that.$api.newmobile_post(info).then((res) => {
          if (res.errcode === 0) {
            that.$message.success("验证码发送成功，请用新手机号查收验证码");
            that.time.count = 60;
            that.time.show = false;
            that.time.timer = setInterval(() => {
              if (that.time.count > 0) {
                that.time.count--;
              } else {
                that.time.show = true;
                clearInterval(that.time.timer);
                that.time.timer = null;
                that.time.count = "";
              }
            }, 1000);
          }
        });
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.time.timer);
    this.time.timer = null;
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style lang="scss" scoped>
.usermod_box {
  width: 1440px;
  margin: auto;
  margin-top: 50px;
  .binding_ii {
    .out_code {
      text-align: center;
    }
    .out_hint {
      margin-top: 30px;
      text-align: center;
      font-size: 13px;
    }
    .set_binding {
      width: 500px;
      margin: auto;
      .input {
        .tell {
          width: 400px;
        }
      }
      .title {
        font-size: 16px;
        font-weight: bold;
        line-height: 80px;
      }
      .wx_code {
        .title {
          font-size: 18px;
        }
        .tell {
          width: 400px;
        }
        .code {
          text-align: center;
          background: #fff;
          padding: 50px 0;
          .img {
            width: 64px;
            height: 64px;
            margin: auto;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .name {
            margin-top: 10px;
            font-size: 14px;
          }
        }
      }
    }
    .tell_list {
      width: 400px;
      margin: auto;
      margin-top: 100px;
      .title {
        font-size: 16px;
        font-weight: bold;
        line-height: 80px;
      }
      .iconfont {
        font-size: 110px;
        color: #f2c811;
      }
      .code_img {
        margin: 20px 0;
        .el-button {
          width: 125px;
          text-align: center;
          background: #f2c811;
          color: #333333;
          border-radius: 0;
        }
      }
      .set_btn {
        text-align: center;
      }
    }
  }
}
</style>
